import Vue from 'vue'
import SvgVue from 'svg-vue'
window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Vue = Vue

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(require('vue-cookies'))
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(SvgVue)

Vue.$cookies.config('7d')

Vue.component('card-page', require('./components/Front/CardPage.vue').default)
Vue.component('tenant-individual', require('./components/Front/TenantIndividual.vue').default)
Vue.component('la-vie-hamburg', require('./components/Front/tenants/LaVieHamburg.vue').default)
Vue.component('bellevue-hamburg', require('./components/Front/tenants/Bellevue.vue').default)
Vue.component('smoky-terrace', require('./components/Front/tenants/SmokyTerrace.vue').default)
Vue.component('ela-lounge', require('./components/Front/tenants/ElaLounge.vue').default)
Vue.component('rouge-hamburg', require('./components/Front/tenants/Rouge.vue').default)
Vue.component('havana-lounge', require('./components/Front/tenants/HavanaLounge.vue').default)
Vue.component('marketoolz', require('./components/Front/tenants/Marketoolz.vue').default)
Vue.component('b7hamburg', require('./components/Front/tenants/B7Hamburg.vue').default)
Vue.component('enjoy-harburg', require('./components/Front/tenants/EnjoyHarburg.vue').default)

Vue.component('card-page-v2', require('./components/Front/CardPageV2.vue').default)

const app = new Vue({
    el: '#app',
});
