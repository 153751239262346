<template>
  <div>
    <!-- CUSTOMER CAPTURE -->
    <transition name="slide">
      <div v-if="mode == 'capture-customer'">
        <div class="screen-top-bar p-3">
          <a href="" @click.prevent="backToMainScreen()"><i class="fa fa-arrow-left"></i></a> Kundendaten erfassen
        </div>

        <div class="container mt-3">
          <p>Aufgrund der staatlichen Vorgaben sind wir gezwungen, eure Kundendaten zu erfassen. Wir bitten um Euer Verständnis und versichern euch, dass eure Daten gut behandelt und nicht zu Werbezwecken genutzt werden.</p>

          <div class="bg-dark p-3">
            <!-- Spinner -->
            <div v-if="customer.isStoring" class="text-center">
              <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <!-- FORM -->
            <div v-if="!customer.isStoring">
              <div class="row">
                <div class="col">
                  <input type="text" class="form-control" placeholder="Vorname" v-model="customer.data.first_name">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Nachname" v-model="customer.data.last_name">
                </div>
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" placeholder="Straße" v-model="customer.data.address_street">
              </div>

              <div class="row">
                <div class="col">
                  <input type="text" class="form-control" placeholder="PLZ" v-model="customer.data.address_zip">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Stadt" v-model="customer.data.address_city">
                </div>
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" placeholder="Email-Adresse" v-model="customer.data.address_email">
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" placeholder="Telefonnummer" v-model="customer.data.phone">
              </div>

              <div class="">
                <div class="alert alert-danger" v-if="!customerFormValid">Sorry! Alle Felder bis auf Email-Adresse müssen ausgefüllt werden.</div>

                <button
                  @click.prevent="storeCustomer()"
                  class="btn btn-success btn-block">Weiter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- MENU -->
    <transition name="slide">
      <div v-if="mode == 'show-all'">

        <div class="customer-is-captured" v-if="$cookies.isKey('customer-is-captured') && $cookies.get('customer-is-captured') == this.store.slug">
          <i class="fa fa-check-circle"></i>
        </div>

        <!-- MAIN MENU -->
        <div class="display--main-drawer-icon">
          <a href="#" @click.prevent="openMainDrawer()"><i class="fa fa-bars"></i></a>
        </div>

        <!-- DRAWER -->
        <transition name="slide">
          <div
            v-if="mainDrawer"
            class="main-drawer-modal">
            <div class="main-drawer-body">

              <div class="display--main-drawer-close-icon">
                <a href="#" @click.prevent="closeMainDrawer()"><i class="fa fa-close"></i></a>
              </div>

              <a
                href="#"
                class="drawer-navigation-item"
                @click.prevent="scrollToTop()"><i class="fa fa-arrow-up"></i> Nach oben scrollen</a>

              <hr class="separator">

              <a
                href="#"
                class="drawer-navigation-item"
                v-for="category in store.cards[0].categories"
                @click.prevent="scrollToCategory(category.slug)">{{ category.name }}</a>

              <hr class="separator">

              <a
                href="#"
                class="drawer-navigation-item"
                @click.prevent="scrollToTop()"><i class="fa fa-arrow-top"></i> Hygienevorschriften</a>
              <a
                href="#"
                v-if="store.has_customer_capture"
                class="drawer-navigation-item"
                @click.prevent="openCustomerCapture()"><i class="fa fa-arrow-top"></i> Als Gast eintragen</a>

              <hr class="separator">

              <div
                class="row drawer-social-media"
                v-if="(store.instagram !== undefined && store.instagram !== null && store.instagram.length > 0) || (store.facebook !== undefined && store.facebok !== null && store.facebook.length > 0)">
                <div class="col">
                  <a :href="'https://www.instagram.com/' + store.instagram" target="_new" v-if="store.instagram !== undefined && store.instagram.length > 0"><span class="social-wrapper"><i class="fa fa-instagram"></i></span></a>
                  <a :href="'https://www.facebook.com/' + store.facebook" target="_new" v-if="store.facebook !== undefined && store.facebook.length > 0"><span class="social-wrapper"><i class="fa fa-facebook"></i></span></a>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- LOGO -->
        <div class="text-center p-4 display--logo">
          <div class="overlay">
            <div class="overlay-animation"></div>
            <div class="overlay-animation overlay-bg-2"></div>
            <div class="overlay-animation overlay-bg-3"></div>
          </div>
          <div class="logo-container">
            <slot name="logo"></slot>
          </div>
        </div>

        <!-- ENTRY TEXT -->
        <div class="container capture-customer" v-if="store.has_customer_capture">
          <div class="" v-if="customer.isStored">
            <p class="p-0 m-0"><strong>Vielen Dank für deine Mitarbeit.</strong></p>
          </div>
          <div class="" v-if="!customer.isStored">
            <p class="p-3 pt-0">Liebe Gäste,<br>solange die Corona-Situation noch aktuell ist, bitten wir euch, besonders auf die Hygienevorschriften zu achten und euch  einzutragen.</p>

            <p class="p-3 pt-0 pt-0" v-if="store.force_customer_capture">Die Karte kann erst eingesehen werden, wenn Du Dich eingetragen hast.</p>

            <p>
              <a href="#" @click.prevent="" class="btn btn-success btn-block btn-sm" style="font-weight: bold;" @click="openCustomerCapture()">Daten eintragen</a>
            </p>
          </div>
        </div>

        <div v-if="!store.force_customer_capture ||
          (store.force_customer_capture && $cookies.isKey('customer-is-captured') && $cookies.get('customer-is-captured') == this.store.slug)">
          <!-- OFFERS -->
          <div
            v-if="store.cards[0].offers.length > 0"
            class="mb-3">
            <div class="container">
              <div class="bg-light p-3">
                <div class="text-center mb-3">
                  <span><strong>Aktuelle Angebote</strong></span>
                </div>

                <table class="table mb-0 pb-0">
                  <tr
                    v-for="(offer, index) in store.cards[0].offers"
                    :key="offer.id">
                    <td>{{ index + 1 }}.</td>
                    <td>
                      <u>{{ offer.name }}</u>
                      <br>
                      {{ offer.readable_timespan }}
                      <br>
                      {{ offer.body }}
                    </td>
                    <td class="text-right">{{ offer.price }}€</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <!-- Categories Navigation: Begin -->
          <div
            :class="'category--' + category.slug"
            class="category-section"
            v-for="category in store.cards[0].categories"
            :id="'category--' + category.slug">

            <div class="category-section-inner p-3 ">
              <div class="category-section-heading p-3">
                <div class="category-section-image"></div>
                <h4>{{ category.name }}</h4>
              </div>

              <div class="category-section-description" v-if="category.description != undefined && category.description.length > 0">{{ category.description }}</div>

              <div
                class="category-product p-3"
                v-for="product in category.products"
                v-if="product.versions.length > 0">
                <div class="row">
                  <div class="col product-title">
                    <strong>{{ product.name }}</strong>
                    <span v-if="product.additives.length" class="product-additives">{{ displayAdditives(product) }}</span>
                    <small v-if="product.note != undefined && product.note.length > 0"><br>{{ product.note }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="category-separator"></div>
          </div>
        </div>
        <div class="product-additives-list">Kennzeichnung der Zusatzsstoffe:<br>{{ productAdditivesList }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TenantIndividual',

  data () {
    return {
      customerFormValid: true,
      mode: 'show-all',
      store: [],
      mainDrawer: false,
      productAdditives: [],
      customer: {
        isStoring: false,
        isStored: false,
        data: {}
      }
    }
  },

  computed: {
    productAdditivesList: function () {
      const productAdditives = _.map(this.productAdditives, (currentObject) => {
        return `${currentObject.additive_number} = ${currentObject.additive_description}`
      })

      return productAdditives.join(', ')
    }
  },

  methods: {
    displayAdditives: function (product) {
      return _.map(product.additives, (currentObject) => parseInt(currentObject.additive_number))
        .sort((a, b) => a - b)
        .join(',')
    },

    load: function () {
      axios.get('/api/additives')
        .then(response => {
          this.productAdditives = response.data
        })

      axios.get('/api/menu/' + this.storeName)
        .then(response => {
          this.store = response.data

          let str = ''
          for (let i in this.store.cards[0].categories) {
            const category = this.store.cards[0].categories[i]
            str += `#category--${category.slug} { .category-section-image {} }`
            str += "\n"
          }
          console.log(str)
        })
    },

    openMainDrawer: function () {
      $('body').addClass('modal-open')
      this.mainDrawer = true
    },

    closeMainDrawer: function () {
      this.mainDrawer = false
      $('body').removeClass('modal-open')
    },

    openCustomerCapture: function () {
      this.closeMainDrawer()
      this.mode = 'capture-customer'
    },

    backToMainScreen: function () {
      this.openCustomerCapture()
      this.mode = 'show-all'
    },

    storeCustomer: function () {
      const neededFields = [
        'first_name', 'last_name', 'address_street',
        'address_zip', 'address_city', 'phone'
      ]

      this.customerFormValid = true
      _.each(neededFields, key => {
        if (this.customer.data[key] == undefined || this.customer.data[key].length <= 2) {
          this.customerFormValid = false
        }
      })

      if (this.customerFormValid) {
        this.customer.isStoring = true

        axios.post('/api/store/' + this.store.seo_url + '/store-customer', this.customer.data)
          .then(response => {
            console.log(response.data)
            this.customer.isStoring = false
            this.customer.isStored = true
            this.customer.data = {}
            this.backToMainScreen()
            this.$cookies.set(
              'customer-is-captured',
              this.store.slug,
              60 * 60 * 3
            )
          })
      }
    },

    scrollToCategory: function (slug) {
      this.closeMainDrawer()
      $('html, body').animate({
        scrollTop: $('#category--' + slug).offset().top
      }, 800)
    },

    scrollToTop: function () {
      this.closeMainDrawer()
      $('html, body').animate({
        scrollTop: 0
      }, 200)
    }
  },

  mounted () {
    this.load()

    console.log(this.$cookies.isKey('customer-is-captured'))

    // Load Units
    // Load Ingredients
  },

  props: [ 'storeName' ],
}
</script>

<style lang="scss">
$tenant-color: #ff7e00;
$tenant-color-rgb: rgb(255, 126, 0);

body {
  background: #000;
  overflow-x: hidden;
  color: #fff;
}

#category--rum-3 { .category-section-image { background-image: url('../images/marketoolz/rum.jpg'); } }
#category--gin-3 { .category-section-image { background-image: url('../images/marketoolz/gin.jpg'); } }
#category--sours { .category-section-image { background-image: url('../images/marketoolz/sours.jpg'); } }
#category--longdrinks-20 { .category-section-image { background-image: url('../images/marketoolz/longdrinks.jpg'); } }
#category--cocktails-18 { .category-section-image { background-image: url('../images/marketoolz/cocktails.jpg'); } }
#category--alkoholfrei { .category-section-image { background-image: url('../images/marketoolz/drivers.jpg'); } }
#category--shots-17 { .category-section-image { background-image: url('../images/marketoolz/shots.jpg'); } }
#category--snacks-26 { .category-section-image { background-image: url('../images/marketoolz/snacks.jpg'); } }

.main-drawer-modal {
  background: $tenant-color-rgb;
  background: -moz-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
  background: linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0072",endColorstr="#000000",GradientType=1);

  a {
    color: #fff;
  }
}

.styling__marketoolz {

  .category-product {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 25);
  }

  .display--logo {
    width: 100%;
    background-size: cover;
    position: relative;
    padding-top: 80px !important;
    padding-bottom: 80px !important;

    .logo-container {
      background: #fff;
      padding: 30px;
      position: relative;
      z-index: 500;
    }

    .la-vie-logo-holder {
      background: #fff;
      padding: 20px;
      position: relative;
      z-index: 500;
    }

    img, svg {
      position: relative;
      max-width: 70%;
      z-index: 10;
    }

    @keyframes slide {
      0% {
        transform:translateX(-25%);
      }
      100% {
        transform:translateX(25%);
      }
    }
  }

  .display--main-drawer-icon,
  .display--main-drawer-close-icon {
    z-index: 999;
  }

  .category-section {
    background: $tenant-color-rgb;
    background: -moz-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0072",endColorstr="#000000",GradientType=1);
  }

  .category-section {
    position: relative;
  }

  .category-section-heading {
    margin-bottom: 20px;
    display: flex;
    background: rgba(0, 0, 0, .5);
    border-bottom: 5px solid $tenant-color;
    text-align: left;
    box-shadow: rgba(255, 126, 0, 0.4) -5px 5px, rgba(255, 126, 0, 0.3) -10px 10px, rgba(255, 126, 0, 0.2) -15px 15px, rgba(255, 126, 0, 0.1) -20px 20px, rgba(255, 126, 0, 0.05) -25px 25px;
    animation: pulse 2s infinite;
    position: relative;
    justify-content: center;
    align-items: center;

    .category-section-image {
      width: 60px;
      height: 60px;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border: 2px solid $tenant-color;
    }

    h4 {
      flex-grow: 1;
      text-align: left;
      padding-left: 10px !important;
      font-weight: bold !important;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 126, 0, 0.9);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 126, 0, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 126, 0, 0);
    }
  }
  @keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 126, 0, 0.9);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 126, 0, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 126, 0, 0);
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .overlay-animation {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #000 50%, $tenant-color 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position: absolute;
    right:-50%;
    top:0;
    z-index:-1;
  }

  .overlay-bg-2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }

  .overlay-bg-3 {
    animation-duration:5s;
  }

  .category-section-description {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    padding: 30px !important;
    background: $tenant-color;
  }

  .capture-customer {
    padding: 20px;
    background: #000;

    p {
      margin: 0 !important;
    }

    .btn {
      text-transform: uppercase;
      font-size: larger;
      border-radius: 0;
      background: $tenant-color;
      border: none;
      font-weight: bold;
      color: #000;
    }
  }

  .category-separator {
    height: 50px;
    background: #000;
    border-top: 4px solid $tenant-color;

  }
}
</style>
