<template>
  <div>
    <!-- CUSTOMER CAPTURE -->
    <transition name="slide">
      <div v-if="mode == 'capture-customer'">
        <div class="screen-top-bar p-3">
          <a href="" @click.prevent="backToMainScreen()"><i class="fa fa-arrow-left"></i></a> Kundendaten erfassen
        </div>

        <div class="container mt-3">
          <p>Aufgrund der staatlichen Vorgaben sind wir gezwungen, eure Kundendaten zu erfassen. Wir bitten um Euer Verständnis und versichern euch, dass eure Daten gut behandelt und nicht zu Werbezwecken genutzt werden.</p>

          <div class="bg-dark p-3">
            <!-- Spinner -->
            <div v-if="customer.isStoring" class="text-center">
              <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <!-- FORM -->
            <div v-if="!customer.isStoring">
              <div class="row">
                <div class="col">
                  <input type="text" class="form-control" placeholder="Vorname" v-model="customer.data.first_name">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Nachname" v-model="customer.data.last_name">
                </div>
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" placeholder="Straße" v-model="customer.data.address_street">
              </div>

              <div class="row">
                <div class="col">
                  <input type="text" class="form-control" placeholder="PLZ" v-model="customer.data.address_zip">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Stadt" v-model="customer.data.address_city">
                </div>
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" placeholder="Email-Adresse" v-model="customer.data.address_email">
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" placeholder="Telefonnummer" v-model="customer.data.phone">
              </div>

              <div class="">
                <div class="alert alert-danger" v-if="!customerFormValid">Sorry! Alle Felder bis auf Email-Adresse müssen ausgefüllt werden.</div>

                <button
                  @click.prevent="storeCustomer()"
                  class="btn btn-success btn-block">Weiter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- MENU -->
    <transition name="slide">
      <div v-if="mode == 'show-all'">
        <div>
          <a :href="`/images/havana-lounge/havana_p${i+1}.jpg`" target="_new" v-for="i in 6" >
            <img :src="`/images/havana-lounge/havana_p${i+1}.jpg`" class="w-100" alt="">
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TenantIndividual',

  data () {
    return {
      customerFormValid: true,
      mode: 'show-all',
      store: [],
      mainDrawer: false,
      productAdditives: [],
      customer: {
        isStoring: false,
        isStored: false,
        data: {}
      }
    }
  },

  computed: {
    productAdditivesList: function () {
      const productAdditives = _.map(this.productAdditives, (currentObject) => {
        return `${currentObject.additive_number} = ${currentObject.additive_description}`
      })

      return productAdditives.join(', ')
    }
  },

  methods: {
    displayAdditives: function (product) {
      return _.map(product.additives, (currentObject) => parseInt(currentObject.additive_number))
        .sort((a, b) => a - b)
        .join(',')
    },

    load: function () {
      axios.get('/api/additives')
        .then(response => {
          this.productAdditives = response.data
        })

      axios.get('/api/menu/' + this.storeName)
        .then(response => {
          this.store = response.data

          let str = ''
          for (let i in this.store.cards[0].categories) {
            const category = this.store.cards[0].categories[i]
            str += `#category--${category.slug} { .category-section-image {} }`
            str += "\n"
          }
          console.log(str)
        })
    },

    openMainDrawer: function () {
      $('body').addClass('modal-open')
      this.mainDrawer = true
    },

    closeMainDrawer: function () {
      this.mainDrawer = false
      $('body').removeClass('modal-open')
    },

    openCustomerCapture: function () {
      this.closeMainDrawer()
      this.mode = 'capture-customer'
    },

    backToMainScreen: function () {
      this.openCustomerCapture()
      this.mode = 'show-all'
    },

    storeCustomer: function () {
      const neededFields = [
        'first_name', 'last_name', 'address_street',
        'address_zip', 'address_city', 'phone'
      ]

      this.customerFormValid = true
      _.each(neededFields, key => {
        if (this.customer.data[key] == undefined || this.customer.data[key].length <= 2) {
          this.customerFormValid = false
        }
      })

      if (this.customerFormValid) {
        this.customer.isStoring = true

        axios.post('/api/store/' + this.store.seo_url + '/store-customer', this.customer.data)
          .then(response => {
            console.log(response.data)
            this.customer.isStoring = false
            this.customer.isStored = true
            this.customer.data = {}
            this.backToMainScreen()
            this.$cookies.set(
              'customer-is-captured',
              this.store.slug,
              60 * 60 * 3
            )
          })
      }
    },

    scrollToCategory: function (slug) {
      this.closeMainDrawer()
      $('html, body').animate({
        scrollTop: $('#category--' + slug).offset().top
      }, 800)
    },

    scrollToTop: function () {
      this.closeMainDrawer()
      $('html, body').animate({
        scrollTop: 0
      }, 200)
    }
  },

  mounted () {
    this.load()

    console.log(this.$cookies.isKey('customer-is-captured'))

    // Load Units
    // Load Ingredients
  },

  props: [ 'storeName' ],
}
</script>

<style lang="scss">
$tenant-color: #e0c9a0;
$tenant-color-rgb: rgb(224, 201, 160);

body {
  background: #000;
  overflow-x: hidden;
  color: #fff;
}

.main-drawer-modal {
  background: $tenant-color-rgb;
  background: -moz-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
  background: linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0072",endColorstr="#000000",GradientType=1);

  a {
    color: #fff;
  }
}

.styling__havana-lounge_v2 {

  .category-product {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 25);
  }

  .display--logo {
    width: 100%;
    background-size: cover;
    position: relative;
    padding-top: 80px !important;
    padding-bottom: 80px !important;

    .logo-container {
      background: #000;
      padding: 30px;
      position: relative;
      z-index: 500;
    }

    .la-vie-logo-holder {
      background: #fff;
      padding: 20px;
      position: relative;
      z-index: 500;
    }

    img, svg {
      position: relative;
      max-width: 70%;
      z-index: 10;
    }

    @keyframes slide {
      0% {
        transform:translateX(-25%);
      }
      100% {
        transform:translateX(25%);
      }
    }
  }

  .display--main-drawer-icon,
  .display--main-drawer-close-icon {
    z-index: 999;
  }

  .category-section {
    background: $tenant-color-rgb;
    background: -moz-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(0deg, $tenant-color-rgb 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0072",endColorstr="#000000",GradientType=1);
  }

  .category-section {
    position: relative;
  }

  .category-section-heading {
    margin-bottom: 20px;
    display: flex;
    background: rgba(0, 0, 0, .5);
    border-bottom: 5px solid $tenant-color;
    text-align: left;
    box-shadow: rgba(224, 201, 160, 0.4) -5px 5px, rgba(224, 201, 160, 0.3) -10px 10px, rgba(224, 201, 160, 0.2) -15px 15px, rgba(224, 201, 160, 0.1) -20px 20px, rgba(224, 201, 160, 0.05) -25px 25px;
    animation: pulse 2s infinite;
    position: relative;
    justify-content: center;
    align-items: center;

    .category-section-image {
      width: 60px;
      height: 60px;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border: 2px solid $tenant-color;
    }

    h4 {
      flex-grow: 1;
      text-align: left;
      padding-left: 10px !important;
      font-weight: bold !important;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(224, 201, 160, 0.9);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(224, 201, 160, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(224, 201, 160, 0);
    }
  }
  @keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(224, 201, 160, 0.9);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(224, 201, 160, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(224, 201, 160, 0);
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .overlay-animation {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #000 50%, $tenant-color 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position: absolute;
    right:-50%;
    top:0;
    z-index:-1;
  }

  .overlay-bg-2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }

  .overlay-bg-3 {
    animation-duration:5s;
  }

  .category-section-description {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    padding: 30px !important;
    background: $tenant-color;
  }

  .capture-customer {
    padding: 20px;
    background: #000;

    p {
      margin: 0 !important;
    }

    .btn {
      text-transform: uppercase;
      font-size: larger;
      border-radius: 0;
      background: $tenant-color;
      border: none;
      font-weight: bold;
      color: #000;
    }
  }

  .category-separator {
    height: 50px;
    background: #000;
    border-top: 4px solid $tenant-color;

  }
}
</style>
