<template>
  <div class="la-vie-logo-holder">
    <svg-vue icon="la-vie/logo"></svg-vue>
  </div>
</template>

<script>

export default {}
</script>
